import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import { getWhiteLabel, getUserProfile, getBusinessProfile, getUserFeatures } from 'graphql/queries/user';
import useAuth from '../../common/hooks/useAuth';
import usePermissions from '../../common/hooks/usePermissions';
import Menu from './partials/Menu';
import useStyles from './styles';
import { setProfile, setBusinessProfile } from 'store/actions/auth';

const Layout = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { children } = props;
  const { onSignOut, userName, userType, isLoggedIn, accountId, userId } = useAuth();
  const { setPermissions } = usePermissions();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (userType === 'admin') {
      onSignOut();
    }
  }, []);

  const { data, loading } = useQuery(getWhiteLabel, {
    variables: { accountId },
    skip: !Boolean(accountId),
  });

  useQuery(getUserFeatures, {
    onCompleted: (data) => setPermissions(data?.getUserFeatures || []),
  });

  useQuery(getUserProfile, {
    variables: { userId },
    skip: !Boolean(userId),
    onCompleted: (data) => dispatch(setProfile(data?.userData)),
  });

  useQuery(getBusinessProfile, {
    variables: { accountId },
    skip: !Boolean(accountId),
    onCompleted: (data) => dispatch(setBusinessProfile(data?.getBusinessProfile)),
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && isLoggedIn && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          {isLoggedIn && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Partner Portal
          </Typography>
          {userName && <Typography className={classes.userName}>{userName}</Typography>}
          {isLoggedIn && (
            <Button onClick={onSignOut} startIcon={<ExitToAppIcon />} className={classes.logoutBtn}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {isLoggedIn && (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <Box className={classes.toolbarIcon}>
            <Box component="a" target="_blank" href={data?.whiteLabel?.site} width="100%" height="100%">
              {!loading ? (
                <img src={data?.whiteLabel?.logoUrl} alt="Partner logo" className={classes.partnerLogo} />
              ) : (
                <Skeleton width="100%" height="100%" data-testid="loader" />
              )}
            </Box>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </Box>
          <Divider />
          <List>
            <Menu />
          </List>
        </Drawer>
      )}
      <Box className={classes.content}>
        <Box className={classes.appBarSpacer} />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
